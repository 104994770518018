import React from "react";

import { Route, Routes } from "react-router-dom";

// import UserData from "../User/UserData";
import TeamMembers from "../Member/TeamMembers";

import Screener from "../Screener/Screener";
import SavedScan from "../SavedScan/SavedScan";
import PrivateRoute from "./PrivateRoutes";

// import UserProfile from "../components/UserProfilePage/UserProfile";
import LoginScreen from "../components/LoginScreen/LoginScreen";
import NotFound from "../components/NotFound";
import AdsPage from "../pages/ads";
import AdminLayout from "../Layout/AdminLayout";
// import { TestLayout } from "../pages/test/TestLayout";
// import { TVChartContainer } from "../components/TVChartContainer";
// import ChartLayout from "./ChartLayout";
import DashboardList from "../components/dashboards/DashboardList";
import SingleDashboardPage from "../components/dashboards/SingleDashboardPage";
// import OptionChain from "../pages/optionchain/SingleOptionChainItem";
// import EmailVerificationPage from "../pages/EmailVerificationPage";
import WatchlistPage from "../pages/watchlists";
import SingleWatchlistPage from "../components/watchlist/SingleWatchlistPage";
import EditWatchlistForm from "../components/watchlist/EditWatchlistForm";
import CreateWatchlistForm from "../components/watchlist/CreateWatchlistForm";
import AlertsPage from "../pages/alerts";
// import AdminAlertsPage from "../pages/adminAlerts";
// import SymbolAlertDetailsPage from "../components/adminAlerts/SymbolAlertDetailsPage";
import Pricing from "../pages/pricing";
import AddOnsForm from "../pages/addOnsForm";
import CartPage from "../pages/cart";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentFailure from "../pages/PaymentFailure";
import PayPal from "../components/payment-gateways/PayPal";
// import UserDashboardPage from "../pages/user-dashboard";
import UserDashboardLayout from "../Layout/UserDashboardLayout";
import UserDashboardHome from "../components/UserDashboard";
import UserPayments from "../components/UserDashboard/Payments";
import UserReferrals from "../components/UserDashboard/Referrals";
import UserSupport from "../components/UserDashboard/Support";
import UserTrading from "../components/UserDashboard/Trading";
import UserProfile from "../components/UserDashboard/Profile";
import AdminDashboardLayout from "../Layout/AdminDashboardLayout";
import SymbolAlertDetailsPage from "../components/AdminDashboard/Alerts/SymbolAlertDetailsPage";
import Users from "../components/AdminDashboard/Users";
import AdminAlertsPage from "../components/AdminDashboard/Alerts";
import HomePage from "../pages/HomePage";
import HomePageLayout from "../Layout/HomePageLayout";
import RecommendedStocksPage from "../pages/recommended-stocks";
import SearchResultsPage from "../pages/SearchResultsPage";
import RefundsAndCancellation from "../pages/refunds-cancellation";
import PrivacyPolicy from "../pages/privacy-policy";
import TermsAndConditions from "../pages/terms-conditions";
import ContactUs from "../pages/contact-us";
import ForgetPasswordPage from "../pages/forget-password/ForgetPasswordPage";
import ResetPasswordPage from "../pages/reset-password/ResetPasswordPage";
import VerifyEmailCodePage from "../pages/verify-email-code/VerifyEmailCodePage";
import UserDevices from "../components/UserDashboard/Devices";
import OptionChainPage from "../pages/optionchain";
import SingleOptionChainItem from "../pages/optionchain/SingleOptionChainItem";
import UnderConstruction from "../components/UnderConstruction";
import SingleSavedScanPage from "../pages/singleSavedScanPage";
import TradeDashboard from "../pages/niftyBankNifty";
// import EmailUpdateVerify from "../pages/email-update-verify";
import { useSelector } from "react-redux";
import VerifyEmailPrompt from "../components/VerifyEmailPrompt";
import SingleUserReferralTable from "../components/AdminDashboard/SingleUserReferralTable";
import UserCouponCodesPage from "../components/AdminDashboard/UserCouponCodesPage";
import SubscriptionPlan from "../components/UserDashboard/SubscriptionPlan";
import UserSettings from "../components/UserDashboard/Settings";
import ActivateUserAccount from "../pages/activate-account";
import GuestLayout from "../Layout/GuestLayout";
import { CreateMarqueeForm } from "../components/AdminDashboard/CreateMarqueeForm";
import AdminDashboard from "../components/AdminDashboard";
import AdminRoute from "./AdminRoute";
import ReferralRewardProgram from "../components/AdminDashboard/ReferralRewardProgram";
import PaymentsDashboard from "../components/AdminDashboard/PaymentsDashboard";
import SingleUserPaymentsTable from "../components/AdminDashboard/PaymentsDashboard/UserPaymentsDashboardTable/SingleUserPaymentsTable";
import SubscriptionsPlans from "../components/AdminDashboard/SubscriptionsPlans";
import CreateTopUp from "../components/AdminDashboard/CreateTopUp";
// import PreviousTradesPerformance from "../pages/previous-trades-performance";
import ThanksForPaying from "../components/common/ThanksForPaying";
import HomeScreenContent from "../components/AdminDashboard/HomeScreenContent";
import UserSupportPanel from "../components/AdminDashboard/UserSupportPanel";
import SinglePaymentDetails from "../components/UserDashboard/Payments/SinglePaymentDetails";
import SingleTicketPageComments from "../pages/SingleTicketPageComments";
import UserRefund from "../components/UserDashboard/UserRefund";
import AdminRefunds from "../components/AdminDashboard/AdminRefunds";
import UploadBanners from "../components/AdminDashboard/UploadBanners";
// import AdvisorDashboard from "../components/AdvisorDashboard";
import AdvisorDashboardLayout from "../Layout/AdvisorLayout";
import AdvisorHome from "../components/AdvisorDashboard/AdvisorHome";
import AdvisorUserSupport from "../components/AdvisorDashboard/AdvisorUserSupport";
import AdvisorDashboard from "../components/AdvisorDashboard";
import AdvisorRoute from "./AdvisorRoute";
import AdvisorHomeBanner from "../components/AdvisorDashboard/AdvisorHomeBanner";
import AdvisorTradeCalls from "../components/AdvisorDashboard/AdvisorTradeCalls";
import AgentDashboardLayout from "../Layout/AgentLayout";
import AgentRoute from "./AgentRoute";
import AgentDashboard from "../components/AgentDashboard";
import AgentStockAdvisoryHistory from "../components/AgentDashboard/stockAdvistoryHis";
import TradeHistory from "../components/AgentDashboard/tradeHistory";

const user = JSON.parse(localStorage.getItem("user"));
// const advisorToken = JSON.parse(localStorage.getItem('advisor_token'));

// ['admin'] means only admin can access. ['advisor', 'admin'] means both advisor and admin can access.
// Since admin should be able to access everything, so 'admin' is included in every list (array)
const USER_TYPES = {
  admin: ["admin"],
  trial: ["trial", "admin"],
  premium: ["premium", "admin"],
  guest: ["guest", "admin"],
  // advisor: ['advisor'],
  advisor: ["advisor", "admin"],
  all: ["admin", "trial", "premium", "guest", "advisor"],
};

const AppRoutes = () => {
  const userState = useSelector((store) => store.auth);
  const USER_DETAILS = userState?.user?.userDetails;

  // if (!user) {
  //   return (<LoginScreen />)
  // }

  return (
    <Routes>
      <Route path="/" element={<UnderConstruction />} />

      <Route path="/" element={<HomePageLayout />}>
        {/* <Route index element={<HomePage />} /> */}
        <Route path="home" element={<HomePage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="refunds-cancellation"
          element={<RefundsAndCancellation />}
        />
        <Route path="terms-conditions" element={<TermsAndConditions />} />
        <Route path="contact-us" element={<ContactUs />} />

        <Route path="pricing" element={<Pricing />} />
        {/* <Route path="cart" element={<CartPage />} /> */}
        <Route path="addons" element={<AddOnsForm />} />
      </Route>

      {/* ADVISOR DASHBOARD PANEL */}
      {/* <Route path="/advisor" element={<PrivateRoute allowedRoles={USER_TYPES.advisor} auth={{ isAuthenticated: !!user }}><AdvisorDashboardLayout /></PrivateRoute>} >
            <Route index element={<PrivateRoute allowedRoles={USER_TYPES.advisor} auth={{ isAuthenticated: !!user }}><AdvisorDashboard /></PrivateRoute>}/>
            <Route path="home" element={<PrivateRoute allowedRoles={USER_TYPES.advisor} auth={{ isAuthenticated: !!user }}><AdvisorHome /></PrivateRoute>}/>
            <Route path="support" index element={<PrivateRoute allowedRoles={USER_TYPES.advisor} auth={{ isAuthenticated: !!user }}><AdvisorUserSupport /></PrivateRoute>}/>
          </Route> */}

      {/* ADVISOR DASHBOARD PANEL */}
      <Route
        path="/advisor"
        element={
          <AdvisorRoute
            allowedRoles={USER_TYPES.advisor}
            auth={{ isAuthenticated: !!user }}
          >
            <AdvisorDashboardLayout />
          </AdvisorRoute>
        }
      >
        {/* the default index home comp not needed as of now */}
        <Route index element={<AdvisorDashboard />} />
        <Route path="advisory-form" element={<AdvisorHome />} />
        <Route path="trade-calls" element={<AdvisorTradeCalls />} />
        <Route path="nifty" element={<TradeDashboard />} />
        <Route path="banner" element={<AdvisorHomeBanner />} />
        <Route path="user-support" element={<AdvisorUserSupport />} />
        <Route
          path="user-support/:ticketID"
          element={<SingleTicketPageComments userType="admin" />}
        />
        <Route path="create-ticker" element={<CreateMarqueeForm />} />
        {/* <Route path="coupons" element={<UserCouponCodesPage />} /> */}
        <Route path="upload-banners" element={<UploadBanners />} />
      </Route>

      {/* Agent DASHBOARD PANEL */}
      <Route
        path="/agent"
        element={
          <AgentRoute
            allowedRoles={USER_TYPES.advisor}
            auth={{ isAuthenticated: !!user }}
          >
            <AgentDashboardLayout />
          </AgentRoute>
        }
      >
        <Route path="home" element={<AgentDashboard />} />
        <Route
          path="stock-advisory-History"
          element={<AgentStockAdvisoryHistory />}
        />
        <Route path="trades-History" element={<TradeHistory />} />
      </Route>

      {/* ADMIN DASHBOARD ROUTE - AUTH REQUIRED, allowedRoles should be updated in both root/parent Route and subroutes */}
      <Route
        path="/"
        element={
          <PrivateRoute
            allowedRoles={USER_TYPES.all}
            auth={{ isAuthenticated: !!user }}
          >
            <AdminLayout />
          </PrivateRoute>
        }
      >
        {/* <Route path="/" element={<HomePageLayout />} >
            <Route index element={<HomePage />} /> */}
        {/* <Route path="home" element={<HomePage />} /> */}
        {/* ONLY FOR THE ADMIN */}

        <Route
          path="screener"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <Screener />
            </PrivateRoute>
          }
        />
        <Route
          path="recommended-stocks"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <RecommendedStocksPage />
            </PrivateRoute>
          }
        />

        {/* COMMENTED OUT THE ADMIN DASHBOARD PAGES/COMPONENTS, as not needed */}
        {/* <Route path="users" element={<PrivateRoute allowedRoles={['admin']} auth={{ isAuthenticated: !!user }}><UserData /></PrivateRoute>}/>
            <Route path="members" element={<PrivateRoute allowedRoles={['admin']} auth={{ isAuthenticated: !!user }}><TeamMembers /></PrivateRoute>}/>
            <Route path="ads" element={<PrivateRoute allowedRoles={['admin']} auth={{ isAuthenticated: !!user }}><AdsPage /></PrivateRoute>}/> */}

        <Route
          path="savedscan"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <SavedScan />
            </PrivateRoute>
          }
        />
        <Route
          path="savedscan/:screenerId"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <SingleSavedScanPage />
            </PrivateRoute>
          }
        />
        {/* <Route path="profile" element={<PrivateRoute allowedRoles={['admin', 'free', 'trial', 'premium']} auth={{ isAuthenticated: !!user }}><UserProfile /></PrivateRoute>}/> */}
        {/* <Route path="nse-option-chain" element={<PrivateRoute allowedRoles={USER_TYPES.all} auth={{ isAuthenticated: !!user }}><OptionChain /></PrivateRoute>}/> */}
        <Route
          path="nse-option-chain"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <OptionChainPage />
            </PrivateRoute>
          }
        />
        <Route
          path="nse-option-chain/:option_symbol"
          element={
            <PrivateRoute
              allowedRoles={USER_TYPES.all}
              auth={{ isAuthenticated: !!user }}
            >
              <SingleOptionChainItem />
            </PrivateRoute>
          }
        />

        {/* IMP: DISABLED AS WEB APP IS NOT LIVE */}
        {/* <Route path="previous-trades-performance" element={<PrivateRoute allowedRoles={USER_TYPES.all} auth={{ isAuthenticated: !!user }}><PreviousTradesPerformance /></PrivateRoute>}/> */}
        {/* <Route path="user-dashboard" element={<PrivateRoute allowedRoles={USER_TYPES.all} auth={{ isAuthenticated: !!user }}><UserDashboardPage /></PrivateRoute>}/> */}

        {/* SEARCH RESULTS */}
        <Route path="search-results" element={<SearchResultsPage />} />

        {/* temp cart */}
        <Route path="cart" element={<CartPage />} />

        {/* DASHBOARDS ROUTE */}
        {/* IMP: REMOVED FROM NAVBAR MENU AS WEB APP IS NOT LIVE */}
        <Route path="dashboards">
          <Route
            index
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <DashboardList />
              </PrivateRoute>
            }
          />
          <Route
            path=":dashboardId"
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <SingleDashboardPage />
              </PrivateRoute>
            }
          />
        </Route>

        {/* WATCHLIST ROUTES */}
        <Route path="watchlist">
          <Route
            index
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <WatchlistPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":watchlistId"
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <SingleWatchlistPage />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:watchlistId"
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <EditWatchlistForm />
              </PrivateRoute>
            }
          />
          <Route
            path="create"
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <CreateWatchlistForm />
              </PrivateRoute>
            }
          />
        </Route>

        {/* ALERTS ROUTES */}
        <Route path="alerts">
          <Route
            index
            element={
              <PrivateRoute
                allowedRoles={USER_TYPES.all}
                auth={{ isAuthenticated: !!user }}
              >
                <AlertsPage />
              </PrivateRoute>
            }
          />
        </Route>

        {/* ROUTES ACCESSIBLE TO EVERY WITH NAVBAR - GUEST LAYOUT */}
        {/* PRIVACY, REFUNDS & CANCELLATION POLICIES */}
        {/* <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refunds-cancellation" element={<RefundsAndCancellation />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="contact-us" element={<ContactUs />} /> */}

        {/* ALERTS ADMIN ROUTES */}
        {/* <Route path="alerts-admin" >
              <Route index element={<PrivateRoute allowedRoles={['admin']} auth={{ isAuthenticated: !!user }}><AdminAlertsPage /></PrivateRoute>}/>
              <Route path=":symbol" element={<PrivateRoute allowedRoles={['admin']} auth={{ isAuthenticated: !!user }}><SymbolAlertDetailsPage /></PrivateRoute>}/>
            </Route> */}

        {/* <Route path="chart" element={<TVChartContainer />} /> */}
      </Route>

      {/* ADMIN DASHBOARD LAYOUT */}
      {/* <Route path="/admin" element={<AdminDashboardLayout />} > */}
      <Route
        path="/admin"
        element={
          <AdminRoute
            allowedRoles={USER_TYPES.admin}
            auth={{ isAuthenticated: !!user }}
          >
            <AdminDashboardLayout />
          </AdminRoute>
        }
      >
        <Route index element={<AdminDashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="nifty" element={<TradeDashboard />} />
        <Route
          path="users/:referralCode"
          element={<SingleUserReferralTable />}
        />
        <Route path="members" element={<TeamMembers />} />
        <Route path="coupons" element={<UserCouponCodesPage />} />
        <Route path="ads" element={<AdsPage />} />
        <Route path="create-ticker" element={<CreateMarqueeForm />} />
        <Route
          path="create-referral-program"
          element={<ReferralRewardProgram />}
        />
        <Route path="payments-dashboard" element={<PaymentsDashboard />} />
        <Route
          path="payments-dashboard/user/:userID"
          element={<SingleUserPaymentsTable />}
        />
        <Route path="alerts" element={<AdminAlertsPage />} />
        <Route path="alerts/:symbol" element={<SymbolAlertDetailsPage />} />
        <Route path="create-plan" element={<SubscriptionsPlans />} />
        <Route path="create-topup" element={<CreateTopUp />} />
        <Route path="home-screen-content" element={<HomeScreenContent />} />
        <Route path="user-support" element={<UserSupportPanel />} />
        <Route
          path="user-support/:ticketID"
          element={<SingleTicketPageComments userType="admin" />}
        />
        <Route path="user-refunds" element={<AdminRefunds />} />
        <Route path="upload-banners" element={<UploadBanners />} />
      </Route>

      {/* USER DASHBOARD LAYOUT & PAGES */}
      {/* <Route path="/user-dashboard" element={<UserDashboardLayout />} > */}
      <Route
        path="/user-dashboard"
        element={
          <PrivateRoute
            allowedRoles={USER_TYPES.all}
            auth={{ isAuthenticated: !!user }}
          >
            <UserDashboardLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<UserDashboardHome />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="subscription" element={<SubscriptionPlan />} />
        <Route path="devices" element={<UserDevices />} />
        <Route path="payments" element={<UserPayments />} />
        <Route path="payments/:paymentID" element={<SinglePaymentDetails />} />
        <Route path="referrals" element={<UserReferrals />} />
        <Route path="support" element={<UserSupport />} />
        <Route
          path="support/:ticketID"
          element={<SingleTicketPageComments userType="user" />}
        />
        <Route path="settings" element={<UserSettings />} />
        <Route path="refund" element={<UserRefund />} />
        <Route path="trading" element={<UserTrading />} />
      </Route>

      {/* ROUTES ACCESSIBLE TO EVERYONE */}
      {/* <Route path="login" element={<SignInPage />} />
            <Route path="register" element={<SignUpPage />} /> */}
      <Route path="login" element={<LoginScreen />} />
      <Route path="register" element={<LoginScreen />} />
      {/* <Route path="email-verify" element={<EmailVerificationPage />} /> */}
      <Route
        path="email-update-verify"
        element={<VerifyEmailPrompt userDetails={USER_DETAILS} />}
      />
      <Route path="thanks-for-paying" element={<ThanksForPaying />} />

      {/* <Route path="pricing" element={<Pricing />} /> */}
      {/* Error in AddOns comp (check auth userDetails) */}
      {/* <Route path="addons" element={<AddOnsForm />} />   */}
      <Route path="payment-success" element={<PaymentSuccess />} />
      <Route path="payment-failure" element={<PaymentFailure />} />
      <Route path="paypal" element={<PayPal />} />

      {/* FORGET & RESET PASSWORD */}
      <Route path="forget-password" element={<ForgetPasswordPage />} />
      <Route path="verify-email-code" element={<VerifyEmailCodePage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />

      {/* GUEST LAYOUT */}
      <Route path="/activate-account" element={<GuestLayout />}>
        <Route index element={<ActivateUserAccount />} />
      </Route>

      {/* CHARTING LIBRARY */}
      {/* <Route path="/chart" element={<ChartLayout />} >
            <Route index element={<TVChartContainer />} />
          </Route> */}

      {/* 404 NOT FOUND ROUTE */}
      <Route path="*" element={<NotFound />} />

      {/* ROUTE FOR TESTING */}
      {/* <Route exact path="/test" element={<TestLayout />}>
            <Route path="test" element={<h1>hello world</h1>} />
          </Route> */}
    </Routes>
  );
};

export default AppRoutes;
