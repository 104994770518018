import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { 
  Container, Typography, TextField, MenuItem, Button, Card, 
  CardContent, IconButton, CircularProgress, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper, Select, InputLabel, FormControl, OutlinedInput, Chip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const TOKEN = JSON.parse(localStorage.getItem("user"))?.access_token;

export default function TradeDashboard() {
  const [symbols] = useState(["NIFTY-I", "FINNIFTY-I", "BANKNIFTY-I"]);
  const [timeframes] = useState(["intraday", "swing"]);
  const [payments, setPayments] = useState([]);
  const [tradeData, setTradeData] = useState([]);
  const [form, setForm] = useState({ symbol: "NIFTY", timeframe: "Intraday", payment: [] });
  const [loading, setLoading] = useState(false);

  // Fetch Payment Plans
  const fetchPaymentPlans = useCallback(async () => {
    try {
      const { data } = await axios.get("/payment/plans", {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      if (Array.isArray(data.response.data)) {
        setPayments(data.response.data.map(plan => ({ id: plan.id, name: plan.name, price: plan.price })));
      }
    } catch (error) {
      console.error("Error fetching payment plans:", error);
    }
  }, []);

  // Fetch Trade Data
  const fetchTradeData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get("/appdashboard/trade/data", {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      setTradeData(Array.isArray(data.response) ? data.response : []);
    } catch (error) {
      console.error("Error fetching trade data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPaymentPlans();
    fetchTradeData();
  }, [fetchPaymentPlans, fetchTradeData]);

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/appdashboard/trade/data", form, {
        headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
      });
      fetchTradeData();
      setForm({ symbol: "NIFTY", timeframe: "Intraday", payment: [] }); // Reset form after submission
    } catch (error) {
      console.error("Error storing trade data:", error);
    }
  };

  // Handle Trade Data Deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete("/appdashboard/trade/data", {
        headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
        data: { id },
      });
      setTradeData(tradeData.filter((trade) => trade.id !== id)); // Optimistic UI update
    } catch (error) {
      console.error("Error deleting trade data:", error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Nifty/Bank Nifty/Fin Nifty</Typography>

      <Card sx={{ p: 2, mb: 4 }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            {/* Symbol Selection */}
            <TextField
              select label="Symbol" fullWidth margin="normal"
              value={form.symbol}
              onChange={(e) => setForm((prev) => ({ ...prev, symbol: e.target.value }))}
            >
              {symbols.map((symbol) => (
                <MenuItem key={symbol} value={symbol}>{symbol}</MenuItem>
              ))}
            </TextField>

            {/* Timeframe Selection */}
            <TextField
              select label="Timeframe" fullWidth margin="normal"
              value={form.timeframe}
              onChange={(e) => setForm((prev) => ({ ...prev, timeframe: e.target.value }))}
            >
              {timeframes.map((time) => (
                <MenuItem key={time} value={time}>{time}</MenuItem>
              ))}
            </TextField>

            {/* Multi-Select Payment Selection with Select All */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Plan</InputLabel>
              <Select
                multiple
                value={form.payment}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.includes("all")) {
                    setForm((prev) => ({
                      ...prev,
                      payment: prev.payment.length === payments.length ? [] : payments.map((plan) => plan.id),
                    }));
                  } else {
                    setForm((prev) => ({ ...prev, payment: value }));
                  }
                }}
                input={<OutlinedInput label="Payment Plan" />}
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selected.map((id) => (
                      <Chip key={id} label={payments.find((plan) => plan.id === id)?.name || id} style={{ margin: 2 }} />
                    ))}
                  </div>
                )}
              >
                <MenuItem key="all" value="all">
                  <em>{form.payment.length === payments.length ? "Deselect All" : "Select All"}</em>
                </MenuItem>
                {payments.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>{plan.name} - ₹{plan.price}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>

      {/* Trade Data Table */}
      {loading ? (
        <CircularProgress sx={{ display: "block", mx: "auto", my: 4 }} />
      ) : tradeData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Symbol</strong></TableCell>
                <TableCell><strong>Timeframe</strong></TableCell>
                <TableCell><strong>Subscription Amount</strong></TableCell>
                <TableCell><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tradeData.map((trade) => (
                <TableRow key={trade.id}>
                  <TableCell>{trade.symbol_name}</TableCell>
                  <TableCell>{trade.timeframe_type}</TableCell>
                  <TableCell>₹ {trade.subscription_amount}</TableCell>
                  <TableCell>
                    <IconButton edge="end" onClick={() => handleDelete(trade.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" sx={{ mt: 2 }}>No trade data available</Typography>
      )}
    </Container>
  );
}
