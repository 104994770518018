import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import styles from "./createMarqueeForm.module.css";
import axiosConfig from "../../../axios/axiosConfig";
import ReactHotToaster from "../../common/ReactHotToaster";
import MuiModal from "../../muiComps/MuiModal";
import TinyMCEEditor from "./TinyMCEEditor";
import TinyMCEContentTable from "./TinyMCEContentTable";
import { fetchBannerScreensList, fetchUserTypeList } from "../../common/UploadBannerForm";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const initialInputDetails = {
  marqueeName: "",
  marqueeDesc: "",
  activateDate: "",
  expiryDate: "",
};

export const CreateMarqueeForm = () => {
  const [inputDetails, setInputDetails] = useState(initialInputDetails);
  const { marqueeName, marqueeDesc, activateDate, expiryDate } = inputDetails;

  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const editorRef = useRef(null); // STATE FOR TINYMCE EDITOR
  const [marqueeList, setMarqueeList] = useState([]);
  const [selectedScreenInput, setSelectedScreenInput] = useState("Dashboard");
  const [openTinyEditor, setOpenTinyEditor] = useState(false);
  const [bannerScreensList, setBannerScreensList] = useState([]);
  const [popupContentList, setPopupContentList] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    const getBannerScreensList = async () => {
      const data = await fetchBannerScreensList();
      setBannerScreensList(data?.response);
    };
    const getUserTypeList = async () => {
      try {
        const data = await fetchUserTypeList();
        const modified_data = (data.response.user_type =
          data.response.user_type.map((type, index) => ({
            id: index,
            type: type,
          })));

        setUserTypes(modified_data);
      } catch (error) { }
    };
    getUserTypeList()
    getBannerScreensList();
  }, []);

  // const appScreenList = popupContentList?.map((item) => item.screen_name);
  // const screenListOptions = initialScreenListOptions?.filter(
  //   (item) => !appScreenList?.includes(item.value)
  // );

  const inputOnChange = (event) => {
    setInputDetails((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // CREATE NEW MARQUEE FORM
  const postMarquee = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "appdashboard/announcement",
        data: {
          userid: USERID,
          announcement_name: marqueeName,
          announcement_description: marqueeDesc,
          activation_date: activateDate,
          expiry_date: expiryDate,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setMarqueeList((prevState) => {
        return [...prevState, data.response];
      });

      setInputDetails(initialInputDetails);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleCreateMarquee = (event) => {
    event.preventDefault();
    postMarquee.mutate();
  };

  // FETCH ALL MARQUEE LIST
  const marqueeQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["marquee"], // dependency
    queryFn: () => fetchMarqueeList(USERID),
    onSuccess: (data) => {
      setMarqueeList(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // DELETE MARQUEE
  const deleteMarquee = async (marquee_id) => {
    try {
      const res = await axiosConfig({
        method: "delete",
        url: `/appdashboard/announcement?announcement_id=${marquee_id}`,
        data: {
          userid: USERID,
        },
      });

      // update state if delete successfully
      setMarqueeList((prevState) => {
        const newMarqueeList = prevState.filter(
          (item) => item.id !== marquee_id
        );
        return newMarqueeList;
      });

      toast.error("Ticker deleted successfully!");
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
  };

  // FETCH ALL TINYMCE CONTENT
  const fetchPopupQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["popup-content"], // dependency
    queryFn: () => fetchPopupContent(USERID),
    onSuccess: (data) => {
      setPopupContentList(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // create content handler
  const handleCreateContent = () => {
    setOpenTinyEditor(true);
  };

  // marqueeName, marqueeDesc, activateDate, expiryDate
  const disableCreateBtn =
    !marqueeName ||
    marqueeName.trim() === "" ||
    !marqueeDesc ||
    marqueeDesc.trim() === "" ||
    !activateDate ||
    activateDate.trim() === "" ||
    !expiryDate ||
    expiryDate.trim() === "";

  return (
    <div style={{ padding: "10px" }}>
      <h2 style={{ marginLeft: "20px", marginTop: "5px" }}>
        Create Marquee Form
      </h2>

      {/* Create Marquee Form */}
      <form className={styles.marqueeForm} onSubmit={(e) => e.preventDefault()}>
        <div className={styles.inputContainer}>
          <label htmlFor="marqueeName">Title:</label>
          <input
            className={styles.input}
            type="text"
            name="marqueeName"
            id="marqueeName"
            value={marqueeName}
            onChange={inputOnChange}
          />
        </div>

        <div className={styles.inputContainer}>
          <label htmlFor="marqueeDesc">Description:</label>
          <textarea
            className={styles.textarea}
            name="marqueeDesc"
            id="marqueeDesc"
            rows={4}
            value={marqueeDesc}
            onChange={inputOnChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "48%" }} className={styles.inputContainer}>
            <label htmlFor="activateDate">Activate Date:</label>
            <input
              className={styles.input}
              type="date"
              name="activateDate"
              id="activateDate"
              value={activateDate}
              onChange={inputOnChange}
              min={new Date().toISOString().slice(0, 10)}
            />
          </div>

          <div style={{ width: "48%" }} className={styles.inputContainer}>
            <label htmlFor="expiryDate">Expiry Date:</label>
            <input
              className={styles.input}
              type="date"
              name="expiryDate"
              id="expiryDate"
              value={expiryDate}
              onChange={inputOnChange}
              min={new Date().toISOString().slice(0, 10)}
            />
          </div>
        </div>

        <button
          type="button"
          className={`${styles.createButton} ${disableCreateBtn ? styles.disabledButton : ""
            }`}
          disabled={disableCreateBtn}
          onClick={handleCreateMarquee}
        >
          Create
        </button>
      </form>

      {/* TEMP MARQUEE DETAILS */}
      <div className={styles.marqueeList}>
        {marqueeList && marqueeList.length > 0 ? (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <p>Sr. No.</p>
              <p>Title</p>
              <p>Description</p>
              <p>Created At</p>
              <p>Expiry Date</p>
              <p>Actions</p>
            </div>
            <div className={styles.tableBody}>
              {marqueeList.map((item, index) => (
                <div key={item.id} className={styles.tableRow}>
                  <p>{index + 1}.</p>
                  <p>{item.announcement_name}</p>
                  <p className={styles.marqueeDescCell}>
                    {item.announcement_description}
                  </p>
                  <p>{new Date(item.created_at).toDateString()}</p>
                  <p>{new Date(item.expiry_date).toDateString()}</p>
                  <DeleteIcon
                    className={styles.deleteIcon}
                    onClick={() => deleteMarquee(item.id)}
                    title="Delete"
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className={styles.emptyMessage}>
            No marquees available to display.
          </p>
        )}
      </div>

      {/* CREATE TINYMCE HERE */}
      <div className={styles.editorContainer}>
        <h2 style={{ marginLeft: "20px", marginTop: "5px" }}>
          Create Modal Content
        </h2>
        <div className={styles.screenListContainer}>
          <label htmlFor="screenPicker" className={styles.screenLabel}>
            Choose Screen:
          </label>

          <select
            id="screenPicker"
            className={styles.selectScreenInput}
            name="selectedScreen"
            value={selectedScreenInput}
            onChange={(event) => setSelectedScreenInput(event.target.value)}
          >
            <option value="" disabled>
              -- Select a Screen --
            </option>
            {bannerScreensList.map((item) => (
              <option key={item.id} value={item.screen_name}>
                {item.screen_name}
              </option>
            ))}
          </select>
          <label htmlFor="screenPicker" className={styles.screenLabel}>
            Select Audience:
          </label>
          <Select
            className={styles.inputControl}
            name="user_type"
            placeholder="Select Audience"
            multiple
            value={selectedUser ? selectedUser.toString().split(",") : []} // Ensure it's a string
            onChange={(event) => {
              const { value } = event.target;
              if (value.includes("all")) {
                // Toggle select all
                const allSelected =
                  selectedUser && selectedUser.toString().split(",").length === userTypes.length;
                setSelectedUser(
                  allSelected ? "" : userTypes.map((user) => user.type).join(",") // Convert array to string
                );
              } else {
                setSelectedUser(value.join(",")); // Convert array to comma-separated string
              }
            }}
            renderValue={(selected) => selected.join(", ")}
          >
            {/* Select All Option */}
            <MenuItem value="all">
              <Checkbox
                checked={
                  selectedUser &&
                  selectedUser.toString().split(",").length === userTypes?.length &&
                  userTypes?.length > 0
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {/* Individual User Types */}
            {userTypes?.map(({ id, type }) => (
              <MenuItem key={id} value={type}>
                <Checkbox checked={selectedUser?.toString().split(",").includes(type)} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>

          <div className={styles.btnContainer}>
            <button
              className={styles.createContentBtn}
              onClick={handleCreateContent}
            >
              Create
            </button>
            {/* Uncomment these buttons if needed */}
            {/* <button className={styles.editContentBtn} onClick={handleEditContent}>
          View/Edit
        </button>
        <button className={styles.deleteContentBtn} onClick={handleDeleteContent}>
          Delete
        </button> */}
          </div>
        </div>
      </div>

      {/* CONTENT DISPLAY TABLE */}
      <TinyMCEContentTable
        tableRowData={popupContentList}
        userID={USERID}
        setOpenTinyEditor={setOpenTinyEditor}
        setPopupContentList={setPopupContentList}
        editorRef={editorRef}
      />

      {/* TinyMCE Modal Popup for creating new content */}
      <MuiModal open={openTinyEditor} setOpen={setOpenTinyEditor}>
        <TinyMCEEditor
          selectedScreen={selectedScreenInput}
          selectedUser={selectedUser}
          setPopupContentList={setPopupContentList}
          userID={USERID}
          crudType="create"
          editorRef={editorRef}
          setOpen={setOpenTinyEditor}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

// UTILITY FUNCTIONS
export const fetchMarqueeList = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    // url: `/appdashboard/announcement?userid=${USERID}&marquee=true`,
    url: `/appdashboard/announcement?userid=${USERID}`,
    data: {},
  });

  return res.data;
};

export const fetchPopupContent = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: `/appdashboard/popup_message/admin`,
    data: {},
  });

  return res.data;
};
