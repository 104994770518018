import React, { useState } from "react";

import { Editor } from "@tinymce/tinymce-react";

import styles from "./TinyMCEEditor.module.css";
import { useMutation } from "@tanstack/react-query";

import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
// import ReactHotToaster from "../../../common/ReactHotToaster";

const TinyMCEEditor = ({
  selectedScreen,
  selectedUser,
  setPopupContentList,
  userID,
  crudType = "create",
  editorRef,
  activeContent,
  setOpen,
}) => {
  // tnimce ref state
  // const editorRef = useRef(null);
  // console.log(editorRef.current.getContent());

  const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  // const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;

  const screenContent =
    activeContent?.screen_content ||
    "<p>This is the initial content of the editor.</p>";

  const [contentTitle, setContentTitle] = useState(activeContent?.title || "");
  const [statusInput, setStatusInput] = useState(
    activeContent?.active === "true" || true
  );
  
  // const [editorContent, setEditorContent] = useState(
  //   screenContent || "<p>This is the initial content of the editor.</p>"
  // );

  // POST TINYMCE TEXT TO BACKEND [CREATE]
  const postTinyMCEText = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/appdashboard/popup_message",
        data: {
          userid: userID,
          title: contentTitle,
          screen_name: selectedScreen,
          screen_content: editorRef.current.getContent(),
          active: statusInput ? "true" : "false",
          user_type:selectedUser
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setPopupContentList((prevState) => {
        return [...prevState, data.response];
      });
      setOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const sendTinyMCEText = () => {
    const consent = window.confirm("Are you sure?");

    if (!consent) return;

    // If consent is true, send tinymce text
    postTinyMCEText.mutate();
  };

  // UPDATE / EDIT TINYMCE CONTENT
  const updateTinyMCEQuery = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "patch",
        url: `appdashboard/popup_message?id=${activeContent?.id}&userid=${userID}`,
        data: {
          userid: userID,
          title: contentTitle,
          screen_content: editorRef.current.getContent(),
          active: statusInput ? "true" : "false",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setPopupContentList((prevState) => {
        return prevState.map((item) => {
          if (item.id === activeContent?.id) {
            return data.response;
          }

          return item;
        });
      });

      setOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // update tinymce content, patch
  const updateTinyMCEContent = () => {
    const consent = window.confirm("Are you sure?");

    if (!consent) return;

    // If consent is true, send tinymce text
    updateTinyMCEQuery.mutate();
  };

  const disablePublishToAppBtn = !contentTitle || contentTitle.trim() === "";

  return (
    <div className={styles.createPostWrapper}>
      <h2 className={styles.mainHeading}>{crudType} content</h2>

      <div className={styles.inputContainer}>
        <div className={styles.titleInputBox}>
          <p>Post Title: </p>
          <input
            className={styles.titleInput}
            placeholder="Type a title here..."
            type="text"
            name="contentTitle"
            id="contentTitle"
            value={contentTitle}
            onChange={(event) => setContentTitle(event.target.value)}
          />
        </div>

        <div className={styles.statusInputBox}>
          <p>Active</p>
          <input
            className={styles.statusInput}
            type="checkbox"
            name="statusInput"
            id="statusInput"
            value={statusInput}
            checked={statusInput}
            // defaultValue={statusInput}
            onChange={(event) => setStatusInput(event.target.checked)}
          />
        </div>
      </div>

      {/* Important points for mobile app post */}
      <div className={styles.instructionsContainer}>
        <ul>
          <li>
            Please select All Text and change font color to{" "}
            <strong>black.</strong>
          </li>
          <li>
            Please keep <strong>Image width of 300px</strong> or below for
            proper rendering on Mobile screen.
          </li>
        </ul>
        {/* <p>Please keep Image width under 300px for proper rendering on Mobile screen.</p> */}
      </div>

      <div className={styles.editorContainer}>
        <Editor
          apiKey="5ny7yci6t16adt8v7trzzri7kni4ikh6ppv3poaawghb9sde"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={screenContent}
          init={{
            // height: 500,
            // menubar: true,
            // plugins: [
            //   "advlist",
            //   "autolink",
            //   "lists",
            //   "link",
            //   "image",
            //   "charmap",
            //   "preview",
            //   "anchor",
            //   "searchreplace",
            //   "visualblocks",
            //   "code",
            //   "fullscreen",
            //   "insertdatetime",
            //   "media",
            //   "table",
            //   "code",
            //   "help",
            //   "wordcount",
            //   "emoticons",
            // ],
            // toolbar:
            //   "undo redo | blocks | " +
            //   "bold italic forecolor | alignleft aligncenter " +
            //   "alignright alignjustify | bullist numlist outdent indent | " +
            //   "removeformat | help" +
            //   "emoticons",
            // content_style:
            //   "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            selector: "textarea#open-source-plugins",
            plugins:
              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
            editimage_cors_hosts: ["picsum.photos"],
            menubar: "file edit view insert format tools table help",
            toolbar:
              "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
            autosave_ask_before_unload: true,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            file_picker_callback: (callback, value, meta) => {
              /* Provide file and text for the link dialog */
              if (meta.filetype === "file") {
                callback("https://www.google.com/logos/google.jpg", {
                  text: "My text",
                });
              }

              /* Provide image and alt text for the image dialog */
              if (meta.filetype === "image") {
                callback("https://www.google.com/logos/google.jpg", {
                  alt: "My alt text",
                });
              }

              /* Provide alternative source and posted for the media dialog */
              if (meta.filetype === "media") {
                callback("movie.mp4", {
                  source2: "alt.ogg",
                  poster: "https://www.google.com/logos/google.jpg",
                });
              }
            },
            height: 500,
            image_caption: true,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            contextmenu: "link image table",
            skin: useDarkMode ? "oxide-dark" : "oxide",
            content_css: useDarkMode ? "dark" : "default",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
          }}
        />
      </div>

      <div className="buttonsContainer">
        {crudType === "create" ? (
          <button
            className={styles.createPostBtn}
            disabled={disablePublishToAppBtn}
            onClick={sendTinyMCEText}
          >
            Publish to App
          </button>
        ) : null}

        {crudType === "edit" ? (
          <button className={styles.updateBtn} onClick={updateTinyMCEContent}>
            Update & Publish
          </button>
        ) : null}
      </div>

      {/* REACT HOT TOAST */}
      {/* <ReactHotToaster /> */}
    </div>
  );
};

export default TinyMCEEditor;
