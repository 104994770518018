import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./TinyMCEContentTable.module.css";
import MuiModal from "../../../muiComps/MuiModal";
import TinyMCEEditor from "../TinyMCEEditor";
// import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";

const TinyMCEContentTable = ({
  tableRowData,
  userID,
  setOpenTinyEditor,
  setPopupContentList,
  editorRef,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fetchedContent, setfetchedContent] = useState({});

  // delete content handler
  const deleteContent = async (content_id) => {
    // Show a confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );

    // If the user confirms the deletion
    if (confirmDelete) {
      try {
        const res = await axiosConfig({
          method: "delete",
          url: `appdashboard/popup_message?id=${content_id}`,
          data: {},
        });

        if (res.status === 200 || res.statusText === "OK") {
          toast.success("Item deleted successfully!");

          setPopupContentList((prevState) => {
            return prevState.filter((item) => item.id !== content_id);
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.response || "Something went wrong");
      }
    }
  };

  return (
    <div style={{ marginLeft: "20px", marginTop: "5px" }} className="container">
      <h2>Modal Content Table</h2>
      <div style={{ marginTop: "10px" }}></div>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Screen</th>
            <th scope="col">Publish Date</th>
            <th scope="col">Audience</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableRowData.map((item) => {
            const formattedDate = new Date(item.created_at).toDateString();
            return (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.screen_name}</td>
                <td>{formattedDate}</td>
                <td>
                {item.user_type}
                {/* <span
                    className={`${styles.statusCircle} ${
                      item.active ? styles.active : styles.inactive
                    }`}
                    aria-label={item.active ? "Active" : "Inactive"}
                  ></span> */}
                </td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <EditIcon
                      className={styles.editBtn}
                      onClick={() => {
                        setOpenTinyEditor(false);
                        setfetchedContent(item);
                        setIsEditing(true);
                      }}
                      title="edit"
                    />
                    <DeleteIcon
                      className={styles.deleteBtn}
                      onClick={() => deleteContent(item.id)}
                      title="Delete"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* TinyMCE Modal Popup */}
      <MuiModal open={isEditing} setOpen={setIsEditing}>
        <TinyMCEEditor
          selectedScreen={fetchedContent.screen_name}
          screenContent={fetchedContent.screen_content}
          activeContent={fetchedContent}
          userID={userID}
          crudType="edit"
          editorRef={editorRef}
          setOpen={setIsEditing}
          setPopupContentList={setPopupContentList}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default TinyMCEContentTable;

//
